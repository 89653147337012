import { getRedirectToCompletePath } from './appRedirect';
import { setTokenCookie } from './cookie';

const LOGIN_ROUTE = '/login';
// const DASHBOARD_ROUTE = '/dashboard';  //temporary comment out, not part of MVP
const BUSINESS_MEETING_ROUTE = '/sales-management';

/**
 * Determines if the current page is the root ("/") or a login path.
 *
 * @returns {boolean} True if the current path is root or starts with the login route; otherwise, false.
 */
const isRootOrLoginPath = () => {
  const path = window.location.pathname;
  return path === '/' || path.startsWith(LOGIN_ROUTE);
};

/**
 * Determines the path to redirect to after a successful login.
 *
 * @returns {string} The path to redirect the user to after login. Defaults to the dashboard route if no specific redirect path is provided.
 */
const getRedirectPathFromLogin = () => {
  return getRedirectToCompletePath() ?? BUSINESS_MEETING_ROUTE;
};

/**
 * Redirects the user from the default host to a subdomain, handling logout from the main domain if necessary.
 *
 * Before redirection, it validates the user's path and clears the default host's local storage to log out the user.
 * The user is redirected based on whether they are at the root or login path,
 * or to the same path within the new subdomain.
 *
 * @param {string} subdomain - The subdomain to redirect the user to.
 * @returns {Promise<boolean>} True if the redirection is successful; otherwise, false.
 */
const redirectFromDefaultHost = async (subdomain) => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const path = window.location.pathname;

  // Passes the tokens from main host to the subdomain
  setTokenCookie();

  if (!isRootOrLoginPath()) {
    window.location = '/403';
    return true;
  }

  const withSubdomain = `${protocol}//${subdomain}.${host}`;
  const newPath = isRootOrLoginPath() ? getRedirectPathFromLogin() : path;
  window.location = `${withSubdomain}${newPath}`;
  return true;
};

/**
 * Handles redirection logic for when the user is on a subdomain and needs to be redirected.
 *
 * @returns {Promise<boolean>} True if a redirection is performed; false if the function completes without redirection.
 */
const redirectFromSubdomain = async () => {
  if (isRootOrLoginPath()) {
    window.location = getRedirectPathFromLogin();
    return true;
  }
};

/**
 * Redirects the user based on their subdomain status. If the user is on the default host, they may be redirected to a subdomain.
 * If the user is already on a subdomain, they may be redirected within that subdomain.
 *
 * @param {string} subdomain - The subdomain to potentially redirect the user to.
 * @returns {Promise<boolean>} True if a redirection occurs; otherwise, false.
 */
export const redirectAsSubdomain = async (subdomain) => {
  const host = window.location.host;

  if (!subdomain) return false;

  if (process.env.REACT_APP_APP_DOMAIN === host) {
    return redirectFromDefaultHost(subdomain);
  }

  if (host.startsWith(subdomain)) {
    return await redirectFromSubdomain();
  }

  if (!host.startsWith(subdomain)) {
    window.location = '/403';
    return true;
  }

  return false;
};
